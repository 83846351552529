<mat-card fxFlex fxLayout="column" fxLayoutAlign="center center">
    <mat-card-header fxFlex>
      <mat-card-title>
        {{ loaderData.title }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content fxFlex >
      <mat-progress-bar fxFlex *ngIf="isProgressBar" mode="query"></mat-progress-bar>
      <mat-progress-spinner fxFlex *ngIf="isSpinner" mode="indeterminate" diameter="32" ></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
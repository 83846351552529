declare var require: any
export const environment = Object.assign({
    production: false,
    appVersion: require('../../package.json').version,
    wootric: {
      accountToken: null, //'NPS-EU-a2f8b560',
      forceSurvey: false
    },
    theme: 'vismaRaet',
    defaultLanguage: 'nl',
    mock: true,
    mockBasePath: null,
    useSubAsUserName: true,    
    dynamicTheme: '[["localhost:4200","vismaYouServe"],["localhost:4201","vismaRaet"]]',
    languages: 
    [
      {
        "title": "Nederlands",
        "code": "nl",
        "flag": "assets/images/flags/NL.svg"
      },
      {
        "title": "English",
        "code": "en",
        "flag": "assets/images/flags/GB.svg"
      },
      {
        "title": "Čeština",
        "code": "cs",
        "flag": "assets/images/flags/CZ.svg"
      },      
      {
        "title": "Deutch",
        "code": "de",
        "flag": "assets/images/flags/DE.svg"
      },
      {
        "title": "Français",
        "code": "fr",
        "flag": "assets/images/flags/FR.svg"
      },
      {
        "title": "Hrvatski",
        "code": "hr",
        "flag": "assets/images/flags/HR.svg"
      },
      {
        "title": "Magyar",
        "code": "hu",
        "flag": "assets/images/flags/HU.svg"
      },
      {
        "title": "Polski",
        "code": "pl",
        "flag": "assets/images/flags/PL.svg"
      },
      {
        "title": "Română",
        "code": "ro",
        "flag": "assets/images/flags/RO.svg"
      },
      {
        "title": "Slovenský",
        "code": "sk",
        "flag": "assets/images/flags/SK.svg"
      },
      {
        "title": "български",
        "code": "bg",
        "flag": "assets/images/flags/BG.svg"
      }
    ]
  },
  (window as any).appsettings);

import { Component } from '@angular/core';
import { CultureService } from './core/services/culture.service';
import { LoggingService } from './core/services/logging.service';
import { environment } from '../environments/environment';
import { ThemeService } from './layout/themes/theme.service';
import { InboxService } from './core/services/inbox.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private cultureService: CultureService, 
    private logger: LoggingService, 
    private inboxService: InboxService, 
    private themeService: ThemeService) {      
    
    // window.onbeforeunload = (e) => {      
    //     if (inboxService.areThereInboxItemsWithUncompletedTasks()) {
    //       e.preventDefault();
    //       e.returnValue = 'Are you sure you want to leave? You have uncompleted tasks';
    //     }      
    // };

    this.cultureService.initializeLanguage();

    let themeName: string = null;
    let dynamicTheme = false;

    // First look if dynamic theme is applicable
    if (environment.dynamicTheme) {
      try {
        const dynamicThemes = JSON.parse(environment.dynamicTheme);
        themeName = this.themeService.getDynamicThemeName(dynamicThemes);
      } catch (e) {
        this.logger.error('Error parsing dynamicTheme: ' + environment.dynamicTheme);
      }
    } 

    // If no dynamic theme is applicable, look for a static theme
    if (!themeName) {
      themeName = environment.theme;
    } else {
      dynamicTheme = true;
    }
    
    if (this.themeService.getTheme(themeName)) {
      this.themeService.setTheme(themeName);
    } else {
      this.logger.warn('Unable to switch to theme: ' + themeName);
    }    

    this.logger.debug('Starting application...');
    this.logger.debug('Version: ' + environment.appVersion);
    this.logger.debug('Environment theme: ' + themeName + (dynamicTheme ? ' (dynamic)' : ''));
    this.logger.debug('Environment routingApiUrl: ' + environment.routingApiUrl);    
    this.logger.debug('Environment authenticationUrl: ' + environment.authenticationUrl);
    this.logger.debug('Environment urlToDomain: ' + environment.url_to_domain);
    this.logger.debug('Environment urlToTheme: ' + environment.dynamicTheme);
  }
}

<div *ngIf="message" class="message error-message" [ngClass]="{'error-message': type=='error', 'info-message': type=='info', 'success-message': type=='success', 'warning-message': type=='warning'}" fxFlex fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="12px">
    <div class="logo">
        <div class="icon"></div>
        <img *ngIf="type=='error'" class="image" src="assets\images\Error24.svg">
        <img *ngIf="type=='info'" class="image" src="assets\images\Information24.svg">
        <img *ngIf="type=='success'" class="image" src="assets\images\Success24.svg">
        <img *ngIf="type=='warning'" class="image" src="assets\images\Warning24.svg">
    </div>
    <div class="message-text" fxFlex>
        {{message}}
    </div>
</div>
import { Theme } from './symbols';

export const otto: Theme = {
    name: 'otto',
    base: 'visma',
    properties: {      
        '--header-left-color': '#FFFFFF',
        '--header-middle-color': '#FFFFFF',
        '--header-right-color': '#FFFFFF',
        '--header-text-color': '#555555',
        '--header-border-color': '#CCCCCC',
        '--header-height': '90px',
        '--header-shadow': '0 0.5rem 1rem 0 rgba(80, 88, 80, 0.25)',       
        '--logo-width': '154px',
        'logo': 'assets/images/otto-work-force.png',
        'login-header-logo': 'assets/images/otto-work-force.png',
        '--login-header-logo-width': '154px',
        'login-footer-logo': 'assets/images/poweredBy_Visma_logo.png'
    }
  };

<div class="login-container" fxLayoutAlign.gt-xs="center center" fxLayout.xs="column" [ngClass]="{'loading': loading}">
    <div class="login-dialog" ngClass.gt-xs="floating" fxFlex fxLayout="column" role="main">       
        <div class="header" fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
            <img class="image" alt="Digital signing" src="{{headerLogoSrc}}">            
        </div>    
        <div fxFlex class="title" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
            <h1 class="title-text" translate>login.title</h1>
        </div>
        <div fxFlex="grow" fxLayout="column" class="form" fxLayoutAlign="start start">        
            <div class="content" fxLayout="column" fxLayoutGap="12px"> 
                <h2 class="hide-while-loading">{{'login.welcome' | translate}}</h2>
                <div class="hide-while-loading" *ngIf="!loading && !busy && invitationInfo?.byPhone" fxFlex class="text" translate [translateParams]="{'phone': invitationInfo.phone_number}">login.proceed_phone</div>
                <div class="hide-while-loading" *ngIf="!loading && !busy && invitationInfo?.byEmail" fxFlex class="text" translate [translateParams]="{'email': invitationInfo.email_address}">login.proceed_email</div>
                <div class="busy-icon" fxFlex fxLayoutAlign="center start"> 
                    <mat-progress-spinner *ngIf="busy || proceeding" mode="indeterminate" diameter="32" ></mat-progress-spinner>                
                </div>
                <button mat-raised-button class="button login-button hide-while-loading" [disabled]="proceeding" (click)="onProceedClick()" >{{'login.proceed_button' | translate}}</button>                
                <div *ngIf="languages" fxLayoutAlign="center start" >                
                    <mat-select [(value)]="languageCode" (selectionChange)="onLanguageSelected($event)" aria-label="Language selection" class="hide-while-loading">
                        <mat-select-trigger>
                            <img class="language-flag center" src="{{language.flag}}" ><span class="flag-title">{{language.title}}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let myLang of languages" value="{{myLang.code}}"><img class="language-flag center" src="{{myLang.flag}}" ><span class="flag-title">{{myLang.title}}</span></mat-option>
                    </mat-select>
                </div>
            </div>                
        </div>      
        <div fxFlex></div>  
        <div fxFlex class="footer" fxLayoutAlign="center center">
            <img class="image" alt="IntraData logo" src="{{footerLogoSrc}}">
        </div>             
    </div>
</div>

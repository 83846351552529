import { Theme } from './symbols';

export const visma: Theme = {
    name: 'visma',
    base: null,
    properties: {
        '--font-family': '"Open Sans", sans-serif',
        '--default-text-color': '#282828',
        '--default-link-color': '#116FAE',
        '--disabled-link-color': '#979AAF',
        '--background-color': '#F0F2F5',        
        '--header-left-color': 'rgba(20, 71, 106)',
        '--header-middle-color': '#125C8D',
        '--header-right-color': 'rgba(17, 111, 174)',
        '--header-text-color': '#FFFFFF',
        '--header-shadow': '0 0.5rem 1rem 0 rgba(13, 87, 136, 0.25)',
        '--header-height': '64px',
        '--header-small-height': '54px',
        '--subheader-left-color': '#FFFFFF',
        '--subheader-right-color': '#FFFFFF',
        '--soft-text-color': '#6D6D6D',
        '--box-background-color': '#FFFFFF',
        '--box-card-background-color': '#FFFFFF',
        '--box-card-border-color' : '#BBBBBB',
        '--box-card-text-color': '#282828',
        '--box-card-errortext-color': '#E94454',
        '--box-card-warning-color': '#E94454',
        '--button-shadow': '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
        '--inbox-card-shadow': '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
        '--button-border': '1px solid #dddddd',
        '--inbox-card-border': '1px solid #dddddd',
        '--inbox-container-color': '#606060',
        '--inbox-background-color': '#F0F2F5',
        '--inbox-item-background-color': '#FFFFFF',
        '--inbox-text-color': '#505050',
        '--inbox-document-completed-border-color': '#2D7048',
        '--inbox-document-completed-hover-color': '#cce8a4',
        '--inbox-document-border-color': '#ffaa33',
        '--inbox-document-hover-color': '#ffefd7',
        '--donebox-background-color': '#F0F2F5',
        '--donebox-item-background-color': '#FFFFFF',
        '--donebox-text-color': '#505050',
        '--donebox-document-border-color': '#116FAE',
        '--donebox-document-hover-color': '#c2e7ff',        
        '--logo-width': '132px',        
        '--logo-small-width': '90px',
        '--warning-color': '#ffaa33',
        '--info-color': '#116FAE',
        '--button-accent-color': '#2D7048',
        '--color-green': '#2D7048',
        '--button-login-normal-background-color': '#2D7048',
        '--button-login-disabled-background-color': '#99C35D',
        '--button-login-disabled-text-color': '#CFE5AE',
        '--error-color': '#E94454',
        '--spinner-color': '#116FAE',
        '--login-header-logo-width': '80px',
        '--login-header-height': '100px',
        '--login-header-logo-hor-align': 'center',
        '--login-header-logo-vert-align': 'end',
        'logo': 'assets/images/vismalogowhite.svg',        
        'login-header-logo': 'assets/images/ID_icon_digitaleondertekening-01.png',
        'login-header-background-color': '#FFFFFF',        
        'login-footer-logo': 'assets/images/Digital_Visma_logo.png'
    }
  };

<div class="login-container" fxLayoutAlign.gt-xs="center center" fxLayout.xs="column">
    <div class="login-dialog" ngClass.gt-xs="floating" fxFlex fxLayout="column" role="main">       
        <div class="header" fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
            <img class="image" alt="Digital signing" src="{{headerLogoSrc}}">            
        </div>    
        <div fxFlex class="title" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
            <h1 class="title-text" translate>login.title</h1>
        </div>
        <div fxFlex="grow" class="form">                        
            <form fxFlex [formGroup]="codeForm" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="4px">
                <p *ngIf="!errorMessage && !infoMessage && !busy && otpInfo?.transport==='sms'" class="text center" translate [translateParams]="{'phone': otpInfo.phone_number}">login.codesms_sent</p>
                <p *ngIf="!errorMessage && !infoMessage && !busy && otpInfo?.transport==='email'" class="text center" translate [translateParams]="{'email': otpInfo.email_address}">login.codeemail_sent</p>
                <mat-progress-spinner *ngIf="busy" mode="indeterminate" diameter="32" ></mat-progress-spinner>
                <app-inline-message *ngIf="errorMessage && !busy" class="message" [message]='errorMessage' type='error'></app-inline-message>
                <app-inline-message *ngIf="infoMessage && !busy" class="message" [message]='infoMessage' type='info'></app-inline-message>
                <div fxLayout="column" fxLayoutGap="4px">
                    <p translate>login.code_label</p>                    
                    <app-pin-entry name="code" formControlName="code" [signalSetFocus]="focusPinEntry" [codeLength]="6" [codeRegex]="'^[0-9]{6}$'" [charRegex]="'[0-9]'" [invalidState]="invalidCode" (enter)="onCheckCodeClick()" (changed)="onCodeChanged($event)"></app-pin-entry>                                                                
                </div>                            
                <!-- <div>code: {{codeForm.value.code}}</div> -->
                <div *ngIf="otpInfo?.send_voice_attempts_available>0" class="top-margin">{{'login.nocode' | translate}} <a href="javascript:void(0)" class="nowrap" [class.disabled]="busy" (click)="onCallMeClick()">{{'login.call_me' | translate}}</a></div>
                <button mat-raised-button class="button login-button top-margin" [disabled]="invalidCode || busy || checkFailed" (click)="onCheckCodeClick()">{{'login.login_button' | translate}}</button>
                <div fxFlex></div>
            </form>
        </div>
        <div fxFlex class="footer" fxLayoutAlign="center center">
            <img class="image" alt="IntraData logo" src="{{footerLogoSrc}}">
        </div>             
    </div>
</div>

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { createAjv, JsonSchema } from '@jsonforms/core';
import { TranslateService } from '@ngx-translate/core';
import Ajv, { ErrorObject } from 'ajv';
import { FormDefinition } from 'src/app/core/models/form-definition.model';
import { FormService } from 'src/app/core/services/form.service';
import { InboxService } from 'src/app/core/services/inbox.service';
import { LoggingService } from 'src/app/core/services/logging.service';
import { FormMonitorService, VudRenderers } from 'vud-jsonforms-renderers';
declare var require: any;

@Component({
  selector: 'app-json-form',
  templateUrl: './json-form.component.html',
  styleUrls: ['./json-form.component.scss']
})
export class JsonFormComponent implements OnInit {

  constructor(private formMonitor: FormMonitorService, 
    private formService: FormService,
    private translate: TranslateService,
    private logger: LoggingService,
    private inboxService: InboxService) {
   
  }

  ngOnInit(): void {
    
    this.ajv = createAjv({    
      schemaId: 'id',
      allErrors: true,
      strict: false
    });
    require('ajv-errors')(this.ajv);

    this.ajv.addFormat('postcode', '^[1-9][0-9]{3} ?[A-Za-z]{2}$');
    this.ajv.addFormat('phone', '^((0)[0-9]{2,3}[-]?[1-9][0-9]{6})|((06)[-]?[1-9][0-9]{7})$');
    this.ajv.addFormat('bsn', { validate: (value: string) => {
      if (!value || !value.length) return false;
      if (value.length < 8 || value.length > 9) return false;
      var testValue = (value.length == 8) ? "0" + value : value;
  
      var i = 9, total = 0;
      for (var j = 0; j < 8; j++) {
          total += parseInt(testValue[j]) * i--; 
      }    
      total -= parseInt(testValue[j]);
      return ((total % 11) == 0);
    }});    
  }

  renderers = VudRenderers; 
   
  errors: any;
  formInvalidMessage = false;
  formValidMessage = false;
  ajv : Ajv;
  _schema: JsonSchema = null;
  _uiSchema: any = null;
  _startData = null;
  myData: {};
  submitting = false;
  errorMessage = null;

  i18nObj = {
    translate:
      (key: string, defaultMessage: string) => {
        console.info('translate: ' + key);
        if (key === 'is a required property' || key === 'must be string') {
          return this.translate.instant('form.required');
        }
        
        return defaultMessage;
      }
  }

  @Input() set formDefinition(value: FormDefinition) {
    if (value && value.schema && value.uiSchema) {      
      this._schema = value.schema;
      this._uiSchema = value.uiSchema;
      this._startData = value.data || {};
    }
  };

  @Input() envelopeId: string;
  @Input() documentId: string;

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('jsonForm', { read: ElementRef }) jsonFormElement:ElementRef;

  formChange(myEvent: any) {    
    this.myData = myEvent;
    //console.info('Myform changed! Errors:');
    // this.formInvalidMessage = false;
    // this.enteredData = myEvent;
  }

  onErrors(myErrors: ErrorObject[]) {
    //console.info('Errors:' + myErrors ? myErrors.length : 0 );
    this.errors = myErrors;
  }

  onSubmit() {
    // Reset error
    this.errorMessage = null;

    // Force all fields to be touched and show validation errors
    this.formMonitor.TriggerForceValidate();

    // Focus first field with errors
    let firstErrorElement = this.jsonFormElement.nativeElement.querySelector('input.ng-invalid');
    if (firstErrorElement) {
      firstErrorElement.focus();
      firstErrorElement.scrollIntoView();
    }
    //console.info('First error field foxcussed: ' + this.jsonFormElement.nativeElement.querySelector('input.ng-invalid').id); 

    let validate = this.ajv.compile(this._schema);    
    //console.info('validate.errors: ' + JSON.stringify(validate.errors)) // processed errors

    this.formInvalidMessage = this.errors?.length > 0;
    this.formValidMessage = !this.formInvalidMessage;  
    
    if (this.errors?.length===0) {

      this.submitting = true;

      this.formService.fillForm(this.envelopeId, this.documentId, this.myData).subscribe(data => {
         // Update document fill task
         this.inboxService.setCacheDocumentTaskIsCompleted(this.envelopeId, this.documentId, 'fill', data.isCompleted);

         this.submitting = false;

        this.submit.emit(this.myData);
      }, error => {
        this.errorMessage = this.translate.instant('form.error_submit');
        this.submitting = false;
        this.logger.error(error);
      });  
    }
  }

  onCancel() {
    this.errorMessage = null;
    this.cancel.emit();
  }
}

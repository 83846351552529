import { Theme } from './symbols';

export const vismaRaet: Theme = {
    name: 'vismaRaet',
    base: 'visma',
    properties: {      
        '--logo-width': '280px',
        '--logo-small-width': '200px',     
        '--login-header-height': '70px',
        '--login-header-logo-width': '220px',
        'login-header-logo': 'assets/images/visma-raet-logo-2022.png',        
        'logo': 'assets/images/visma-raet_logo_endorsed_mono-wit_rgb.svg',        
        'login-footer-logo': 'assets/images/poweredBy_Visma_logo.png'
    }
  };

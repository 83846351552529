import { Component, OnInit } from '@angular/core';
import { LoggingService } from 'src/app/core/services/logging.service';
import { CultureService } from 'src/app/core/services/culture.service';
import { ThemeService } from 'src/app/layout/themes/theme.service';
import { Language } from 'src/app/core/models/language.model';

@Component({
  selector: 'app-login-default',
  templateUrl: './login-default.component.html',
  styleUrls: ['./login-default.component.scss', '../shared.scss']
})
export class LoginDefaultComponent implements OnInit {

  constructor(
    private logger: LoggingService,
    private themeService: ThemeService,
    private cultureService: CultureService) { }

  languages: Language[];
  language: Language;
  languageCode: string;

  ngOnInit(): void {
    this.languages = this.cultureService.getAvailableLanguages();
    this.language = this.cultureService.currentLanguage;
    this.languageCode = this.language.code;

    this.logger.debug('Current language = ' + this.language);
  }

  get headerLogoSrc() {
    return this.themeService.getProperty('login-header-logo');
  }

  get footerLogoSrc() {
    return this.themeService.getProperty('login-footer-logo');
  }

  onLanguageSelected(lang: any) {
    this.logger.debug('User changed language to: ' + lang.value);
    this.cultureService.useLanguage(lang.value);
    this.language = this.cultureService.currentLanguage;
    this.languageCode = this.language.code;
  }

}

import { Task } from './task.model';
import { TaskHelper } from './task-helper';

export class InboxDocument {
    
    constructor(public id: string,
                public envelopeId,
                public title: string,
                public subtitle: string,
                public contentType: string,
                public contentSize: number,
                tasks: any) {
        // tasks can be an array of Task objects or an object
        // only if tasks is an object, we need to convert it to an array of Task objects
        if (tasks && !Array.isArray(tasks)) {
            this.tasks = TaskHelper.DocumentTaskFromAny(tasks);         
        } else if (tasks) {
            this.tasks = tasks as Task[];
        } else {
            this.tasks = [];
        }

        this.tasksCompleted = this.getTasksCompleted();
        this.lastCompletedTask = this.getLastCompletedTask();
        this.activeTask = this.getActiveTask();
    }

    tasks: Task[];
    lastCompletedTask: Task;
    activeTask: Task;
    tasksCompleted: boolean;
    
    public get hasApproveTask(): boolean {
        return this.tasks.some(t => t.code === 'approve');        
    }

    public get hasSignTask(): boolean {
        return this.tasks.some(t => t.code === 'sign');        
    }

    public get hasFillTask(): boolean {
        return this.tasks.some(t => t.code === 'fill');        
    }

    setTaskCompleted(taskCode: string, isCompleted: boolean) {
        const task = this.tasks.find(t => t.code === taskCode);
        if (task) {
            task.isCompleted = isCompleted;
        }

        this.tasksCompleted = this.getTasksCompleted();
        this.lastCompletedTask = this.getLastCompletedTask();
        this.activeTask = this.getActiveTask();
    }

    private getTasksCompleted(): boolean {
        return !this.tasks.find(t => !t.isCompleted);
    }

    private getLastCompletedTask(): Task {
        return this.tasks.slice().reverse().find(item => item.isCompleted) as Task;
    }

    private getActiveTask(): Task {
        return this.tasks.find(item => !item.isCompleted) as Task;
    }
}

import { UserInfoService } from './../../core/services/user-info.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ThemeService } from '../themes/theme.service';
import { environment } from '../../../environments/environment';
import { Language } from 'src/app/core/models/language.model';
import { CultureService } from 'src/app/core/services/culture.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private authService: AuthService,
              private themeService: ThemeService,
              private cultureService: CultureService,
              private userInfoService: UserInfoService) { }

  userInfo: any;
  displayName: string;
  language: Language;
  languages: Language[];

  ngOnInit() {
    this.languages = this.cultureService.getAvailableLanguages();
    this.language = this.cultureService.currentLanguage;

    this.userInfoService.get().subscribe(data => {
      this.userInfo = data;
      this.displayName = environment.useSubAsUserName === 'true' ? data.sub : data.name;
    });
  }

  get logoSrc() {
    return this.themeService.getProperty('logo');
  }

  onSignOut() {
    this.authService.signOut();
  }

  onKrabblTheme() {
    this.themeService.setTheme('defaultTheme');
  }

  onVismaTheme() {
    this.themeService.setTheme('visma');
  }

  onVismaDarkTheme() {
    this.themeService.setTheme('visma-dark');
  }

  onVismaBeauTheme() {
    this.themeService.setTheme('visma-beau');
  }

  onLanguageSelect(lang: Language) {
    this.cultureService.useLanguage(lang.code, null)
    this.language = this.cultureService.currentLanguage;
  }
}

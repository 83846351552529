import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { LoginInvitationComponent } from './pages/login/login-invitation/login-invitation.component';
import { LoginDefaultComponent } from './pages/login/login-default/login-default.component';
import { LoginErrorComponent } from './pages/login/login-error/login-error.component';
import { LoginEndComponent } from './pages/login/login-end/login-end.component';
import { LoginCodeComponent } from './pages/login/login-code/login-code.component';

const routes: Routes = [
  { path: 'login', component: LoginDefaultComponent },
  { path: 'logininvitation', component: LoginInvitationComponent },
  { path: 'logincode', component: LoginCodeComponent },
  { path: 'loginerror', component: LoginErrorComponent },
  { path: 'loginend', component: LoginEndComponent },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
          path: '',
          redirectTo: 'inbox',
          pathMatch: 'full'
      },
      {
          path: 'inbox',
          canActivate: [AuthGuardService],
          loadChildren: () => import('./pages/inbox/inbox.module').then(m => m.InboxModule)
      },
      {
        path: 'view/:eid/:did',        
        canActivate: [AuthGuardService],
        loadChildren: () => import('./pages/document-view/document-view.module').then(m => m.DocumentViewModule)
      },
      {
        path: 'approve/:eid/:did',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./pages/document-approve/document-approve.module').then(m => m.DocumentApproveModule)
      },
      {
        path: 'fill/:eid/:did',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./pages/document-fill/document-fill.module').then(m => m.DocumentFillModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

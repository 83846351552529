import { Component } from '@angular/core';
import { LoaderData } from './loader-config';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  constructor(private readonly data: LoaderData) { 
    this.loaderData = data;
  }

  loaderData: LoaderData;

  get isSpinner(): boolean {
    return this.data.loaderType === 'Spinner';
  }

  get isProgressBar(): boolean {
    return this.data.loaderType === 'ProgressBar';
  }
}

<div [@growDown] class="container mat-drawer" *ngIf="opened">
    <p class="message mat-body-strong" [innerHTML]="message"></p>
    <div class="action-row">
        <button *ngFor="let action of actions; let idx=index;"
                type="button" mat-button color="accent"
                (click)="actionClicked(idx)">
            {{action}}
        </button>
    </div>
</div>

import { Theme } from './symbols';

export const olympia: Theme = {
    name: 'olympia',
    base: 'visma',
    properties: {        
        '--background-color': '#F0F2F5',
        '--header-left-color': '#FFFFFF',
        '--header-middle-color': '#FFFFFF',
        '--header-right-color': '#FFFFFF',
        '--header-text-color': '#555555',
        '--header-border-color': '#CCCCCC',
        '--header-height': '90px',         
        '--header-shadow': '0 0.5rem 1rem 0 rgba(80, 88, 80, 0.25)',
        '--logo-width': '180px',                
        '--warning-color': '#ffaa33',                
        'logo': 'assets/images/olympia-logo.svg',
        'login-header-logo': 'assets/images/olympia-logo.svg',
        '--login-header-logo-width': '200px',
        'login-footer-logo': 'assets/images/poweredBy_Visma_logo.png'
    }
  };

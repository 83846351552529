import { InboxDocument } from './inbox-document.model';
import { Task } from './task.model';

export class InboxItem {

    constructor(public id: string,
                public title: string,                
                public subtitle: string,
                public dateScheduled: Date,
                public expirationDate: Date,
                documents: InboxDocument[]) {

        this.documents = documents.map(doc => new InboxDocument(
            doc.id,
            id,
            doc.title,
            doc.subtitle,
            doc.contentType,
            doc.contentSize,
            doc.tasks)) as InboxDocument[];
    }

    documents: InboxDocument[];
    isRemoved: boolean;

    public tasksCompleted(): boolean {
        if (this.documents) {
            return this.documents.every(d => d.tasksCompleted);
        }

        return true;
    }
   
    public getFirstUncompletedDocumentTask(omitSignTask = false): Task {
        for (let doc of this.documents) {
            const uncompletedTask = doc.tasks.find(task => !task.isCompleted && !(omitSignTask && task.code === 'sign'));
            if (uncompletedTask) {
                return uncompletedTask;
            }
        }
        return null;
    }

    public get hasSignTask(): boolean {
        return this.documents.some(doc => doc.hasSignTask);
    }

    public get hasApproveTask(): boolean {
        return this.documents.some(doc => doc.hasApproveTask);
    }

    public get allTasksCompleted(): boolean {
        return this.documents.every(doc => doc.tasksCompleted);
    }

    // To indicate that the inbox item is ready to be signed
    public get allActiveTasksAreSignTasks(): boolean {
        return this.documents.every(doc => doc.tasksCompleted || doc.activeTask.code === 'sign');
    }

    // To indicate that the inbox item is ready to be approved
    public get allActiveTasksAreApproveTasks(): boolean {
        return this.documents.every(doc => doc.tasksCompleted || doc.activeTask.code === 'approve');
    }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from './auth.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private logger: LoggingService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.authService.isAuthenticated()) {
      this.logger.debug('User has refresh token. Proceed to route.');
      return true;
    }

    this.logger.debug('User is not authenticated. Redirect to login.');

    // Check for 'invite' in query string  
    // https://intraoffice.visualstudio.com/IntraOffice/_wiki/wikis/IntraOffice.wiki/23/Invited-login  
    const invite = this.getUrlParameter('invite');
    if (invite != null) {
      this.logger.debug('Invite is present. Redirect to Invitation Login component.');
      this.router.navigate(['/logininvitation'], { queryParams: { invite: invite } });
      // this.performInvitedLogin(invite);
      return;
    }

    // Check for authentication querystring
    // This is temporary code to support auth=<token> access
    const auth = this.getUrlParameter('auth');
    if (auth != null) {
       this.performAuthStringAuthentication(auth);
    } else {
      this.authService.handleTokenAccess();
    }

    if (this.authService.isAuthenticated()) {
      return true;
    }

    // Not authenticated: redirect to default login
    this.logger.debug('Authentication method unknown. Redirect to default login page.');
    this.router.navigate(['/login']);

    return false;
  }

  private getUrlParameter(name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      const results = regex.exec(location.search);
      return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  private performInvitedLogin(invitationCode: string) {
    this.logger.debug('Invitation code in query string: ' + invitationCode);

    this.authService.initiateInvitedLogin(invitationCode);
  }

  private performAuthStringAuthentication(auth: string) {
    this.logger.debug('JWT in query string: ' + auth);
    this.authService.authenticateWithToken(auth);
  }

}

<div class="container">
    <div class="flipper" [@flip]="activePane">
        <div class="sides front">
            <ng-content select="[frontPane]"></ng-content>
        </div>
        <div class="sides back">
            <ng-content select="[backPane]"></ng-content>
        </div>
    </div>
</div>

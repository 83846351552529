import { NgModule, ModuleWithProviders } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedModule } from '../shared/shared.module';
import { SlidePanelComponent } from './slide-panel/slide-panel.component';
import { FlipPanelComponent } from './flip-panel/flip-panel.component';
import { LoaderComponent } from './loader/loader.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayLoadingDirective } from './loader/overlay-loading.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { BannerComponent } from './banner/banner.component';
import { ThemeService } from './themes/theme.service';
import { ThemeDirective } from './themes/theme.directive';
import { ThemeOptions, THEMES, ACTIVE_THEME } from './themes/symbols';
import { LanguagesFilterPipe } from '../core/services/languages.pipe';

@NgModule({
    declarations: [
        LayoutComponent,
        HeaderComponent,
        SlidePanelComponent,
        FlipPanelComponent,
        LoaderComponent,
        OverlayLoadingDirective,
        ThemeDirective,
        BannerComponent,
        LanguagesFilterPipe
    ],
    imports: [
        FlexLayoutModule,
        MatToolbarModule,
        SharedModule,
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        OverlayModule
    ],
    providers: [ThemeService],
    exports: [
        SlidePanelComponent,
        FlipPanelComponent,
        LoaderComponent,
        BannerComponent,
        OverlayLoadingDirective,
        ThemeDirective
    ]
})
export class LayoutModule { 
  static forRoot(options: ThemeOptions): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: [
        {
          provide: THEMES,
          useValue: options.themes
        },
        {
          provide: ACTIVE_THEME,
          useValue: options.active
        }
      ]
    };
  }
}

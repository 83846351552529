import { Component, ViewChild, ElementRef, OnInit, OnDestroy, Input, ViewEncapsulation, Output, EventEmitter, NgZone } from '@angular/core';
import { RenderProviders } from 'src/app/core/models/render-providers.model';
import { ScrollData } from 'src/app/core/models/scroll-data.model';
import { TranslateService } from '@ngx-translate/core';

declare let $: any;

declare global {
  interface Window { my: any; }
}

@Component({
  selector: 'app-endless-scroll-renderer',
  templateUrl: './endless-scroll-renderer.component.html',
  styleUrls: ['./endless-scroll-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EndlessScrollRendererComponent implements OnInit, OnDestroy {

  myRenderProviders: any;
  showBusy = false;
  showPages = false;
  private endlessScrollEl: any;

  @Input() set renderProviders(value: RenderProviders) {
    this.myRenderProviders = value;

    if (value) {

      this.showBusy = false;
      this.showPages = true;

      // Delay to make sure DOM is ready
      setTimeout(() => {
        this.initializeEndlessScrollControl(value.renderId);
      }, 20);
    }
  }

  @Input() showFooter: boolean;

  @Input() set zoom(value: number) {
    if (this.endlessScrollEl) {
      this.endlessScrollEl.zoom(value);
    }
  }

  @ViewChild('documentParent', { static: true }) docParentEl: ElementRef;
  @ViewChild('scrollRendererElement', { static: true }) scrollRendererEl: ElementRef;
  @ViewChild('busyElement', { static: true }) busyEl: ElementRef;
  @ViewChild('errorElement', { static: true }) errorEl: ElementRef;
  @ViewChild('footerElement', { static: true }) footerEl: ElementRef;

  @Output()
  sign: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  download: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  scroll: EventEmitter<ScrollData> = new EventEmitter<ScrollData>();

  @Output()
  documentReady: EventEmitter<void> = new EventEmitter<void>();

  constructor(private ngZone: NgZone, private translate: TranslateService) {}

  ngOnInit() {
      window.my = window.my || {};
      window.my.scrollRenderer = window.my.scrollRenderer || {};
      window.my.scrollRenderer.signatureClickCallback = this.signatureClickCallback.bind(this);
      window.my.scrollRenderer.scrollCallback = this.scrollCallback.bind(this);
      window.my.scrollRenderer.downloadCallback = this.downloadClickCallback.bind(this);
      window.my.scrollRenderer.documentReadyCallback = this.documentReadyCallback.bind(this);
  }

  ngOnDestroy() {
      window.my.scrollRenderer.signatureClickCallback = null;
  }

  buttonSign() {
      this.sign.emit('test');
  }

  signatureClickCallback(signatureId: string) {
      this.ngZone.run(() => {
          this.sign.emit(signatureId);
      });
  }

  downloadClickCallback() {
    this.ngZone.run(() => {
        this.download.emit();
    });
}

  scrollCallback(scrollData: ScrollData) {
    this.ngZone.run(() => {
        this.scroll.emit(scrollData);
    });
  }

  documentReadyCallback() {
    this.ngZone.run(() => {
        this.documentReady.emit();
    });
  }

  initializeEndlessScrollControl(documentId: string) {
    // Update endlessScrollControl
    this.endlessScrollEl = $(this.scrollRendererEl.nativeElement).endlessScrollControl({      
      renderProviders: this.myRenderProviders,
      startWidth: 800,
      signatureField: null,      
      backClick: null,
      documentId: documentId,
      onScroll: window.my.scrollRenderer.scrollCallback,   
      onDocumentReady: window.my.scrollRenderer.documentReadyCallback,   
      blankImgUrl: 'assets/images/blank.gif',
      scrollElement: $(this.docParentEl.nativeElement),
      errorElement: $(this.errorEl.nativeElement),
      busyElement: $(this.busyEl.nativeElement),
      downloadClick: this.download.observers.length > 0 ? window.my.scrollRenderer.downloadCallback : null,
      pagerText: this.translate.instant('documentview.pager_text'),
      footerElement: this.showFooter ? jQuery(this.footerEl.nativeElement) : null,
      footerBlockTemplate: '<div class="footer-block" style="width:{width}px">FOOTER</div>'
    }).data('endlessScrollControl');
  }
}

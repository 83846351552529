import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoginService } from 'src/app/core/services/login.service';
import { InvitationInfo } from 'src/app/core/models/invitation-info.model';
import { LoggingService } from 'src/app/core/services/logging.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OTPInfo } from 'src/app/core/models/otp-info.model';
import { environment } from './../../../../environments/environment';
import { CultureService } from 'src/app/core/services/culture.service';
import { OtpService } from 'src/app/core/services/otp.service';
import { ThemeService } from 'src/app/layout/themes/theme.service';
import { Language } from 'src/app/core/models/language.model';

@Component({
  selector: 'app-login',
  templateUrl: './login-invitation.component.html',
  styleUrls: ['./login-invitation.component.scss', '../shared.scss']
})
export class LoginInvitationComponent implements OnInit {

  constructor(private authService: AuthService,
              private logger: LoggingService,
              private route: ActivatedRoute,
              private router: Router,
              private themeService: ThemeService,
              private otpService: OtpService,
              private cultureService: CultureService,
              private translate: TranslateService,
              private loginService: LoginService) {}

  invitationInfo: InvitationInfo;
  busy: boolean;
  loading = true;
  proceeding: boolean;
  otpInfo: OTPInfo;
  languages: Language[];
  language: Language;
  languageCode: string;

  @HostListener('window:keyup.enter', ['$event', 'undefined'])
  onEnterOrClick(enterEvent) {
    if (enterEvent) {
      this.onProceedClick();
    }
  }

  get headerLogoSrc() {
    return this.themeService.getProperty('login-header-logo');
  }

  get footerLogoSrc() {
    return this.themeService.getProperty('login-footer-logo');
  }

  ngOnInit(): void {

    // If user is authenticated goto application (/inbox)
    // Login not needed
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/inbox']);
      return;
    }
    
    this.languages = this.cultureService.getAvailableLanguages();
    this.language = this.cultureService.currentLanguage;
    this.languageCode = this.language.code;

    this.translate.reloadLang(this.language.code).subscribe(() => {
      // Get invitation code from query params
      this.route.queryParams.subscribe(params => {
        const invitationCode = params['invite'];

        if (!invitationCode) {
          // If not given: redirect to default login
          this.logger.warn('On Invitation Login component but no invite present! Redirecting to default login.');
          this.router.navigate(['/login']);
          return;
        }

        this.busy = true;

        this.authService.initiateInvitedLogin(invitationCode).subscribe(invitationInfo => {
          this.invitationInfo = invitationInfo;

          // phone_number present: OTP by phone
          // else if email_address present: OTP by e-mail
          this.invitationInfo.byPhone = !!this.invitationInfo.phone_number;
          this.invitationInfo.byEmail = !this.invitationInfo.byPhone && !!this.invitationInfo.email_address;

          // If culture was given: switch language
          if (this.invitationInfo.culture || 'nl-NL') {
            const language = this.invitationInfo.culture.substr(0, 2);
            this.logger.debug('Culture was given: switch to: ' + language + '; style: ' + this.invitationInfo.style);
            this.cultureService.useLanguage(language, this.invitationInfo.style).subscribe(() => {
              this.language = this.cultureService.currentLanguage;
              this.cultureService.setLanguage(this.language.code, this.cultureService.currentStyle);
              this.languageCode = this.language.code;
              this.logger.debug('... new language: ' + this.language.code + '; style: ' + this.cultureService.currentStyle);
              this.loading = false;
            });
          } else {
            this.loading = false;
          }

          this.logger.logEvent('LogInWithInviteStart', {invite: invitationCode})

          this.busy = false;
        }, error => {
          this.loginService.redirectToError(error.error?.message || this.translate.instant('login.error_cannotlogin', {code: error?.status}));
          this.logger.logException(new Error(`LogInWithInviteStart failed for invite: ${invitationCode}.` + JSON.stringify(error)));
          this.busy = false;
          this.loading = false;
        });
      });
    });
  }

  onProceedClick() {
    // Start OTP process
    this.proceeding = true;

    this.authService.createOtp(this.invitationInfo.invitation_code).subscribe(otpInfo => {

      otpInfo.phone_number = this.invitationInfo.phone_number;
      otpInfo.email_address = this.invitationInfo.email_address;

      // if send_sms_attempts_available > 0 then send sms 
      // else if send_email_attempts_available > 0 then send e-mail
      otpInfo.transport = otpInfo.send_sms_attempts_available > 0 ? 'sms' : otpInfo.send_email_attempts_available > 0 ? 'email' : null;

      this.otpService.setOtpInfo(otpInfo);

      if (otpInfo.transport == null) {
        this.logger.logException(new Error(`LogInWithInviteSendOtp failed for invite: ${this.invitationInfo.invitation_code}. No sms or email attempts`));
        this.loginService.redirectToError(this.translate.instant('login.error_cannotsendcode', {code: 123}));
      }

      // Now send the otp by sms or email
      this.authService.otpSend(otpInfo, otpInfo.transport).subscribe(data => {
        // Update otpInfo (attempts have changed)
        // this.loginService.setOtpInfo(data);
        this.otpInfo = data;
        this.otpInfo.phone_number = this.invitationInfo.phone_number;
        this.otpInfo.email_address = this.invitationInfo.email_address;
        this.otpInfo.transport = otpInfo.transport;
        this.otpService.setOtpInfo(this.otpInfo);

        this.logger.logEvent('LogInWithInviteSendOtp', {invite: this.invitationInfo.invitation_code, transport: otpInfo.transport});

        // Switch to code entry page (this will used the stored otp info)
        this.router.navigate(['/logincode']);

      }, error => {
        // Send otp failed
        // Possible errors: Status400BadRequest
        this.logger.logException(new Error(`LogInWithInviteSendOtp failed for invite: ${this.invitationInfo.invitation_code}. ${error.message}`));
        this.loginService.redirectToError(
          error.error?.message ||
          (otpInfo.transport === 'sms' ? this.translate.instant('login.error_cannotsendsms', {code: error?.status}) : this.translate.instant('login.error_cannotsendemail', {code: error?.status})
        ));
      });
    }, error => {
      // Create OTP failed
      // Possible errors: Status429TooManyRequests, Status400BadRequest
      this.logger.logException(new Error(`LogInWithInviteCreateOtp failed for invite: ${this.invitationInfo.invitation_code}. ${error.message}`));

      if (error.status === 429) {
        this.loginService.redirectToError(error.error?.message || this.translate.instant('login.error_invitationtoooften'));
      } else {
        this.loginService.redirectToError(error.error?.message || this.translate.instant('login.error_invitationnotworking', {code: error?.status}));
      }
    });
  }

  onLanguageSelected(lang: any) {
    this.logger.debug('User changed language to: ' + lang.value);
    this.cultureService.useLanguage(lang.value);
    this.language = this.cultureService.currentLanguage;
    this.languageCode = this.language.code;
  }
}

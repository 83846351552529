import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private router: Router) { }

  error: any;

  redirectToError(error: any) {
    this.error = error;
    this.router.navigate(['/loginerror']);
  }
}

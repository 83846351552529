import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

type PaneType = 'front' | 'back';

@Component({
  selector: 'app-flip-panel',
  templateUrl: './flip-panel.component.html',
  styleUrls: ['./flip-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('flip', [
      state('front', style({ transform: 'rotateY(0)' })),
      state('back', style({ transform: 'rotateY(180deg)' })),
      transition('* => *', animate('400ms ease-in-out'))
    ])
  ]
})
export class FlipPanelComponent {
  @Input() activePane: PaneType = 'front';
}

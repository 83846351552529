import { CultureService } from 'src/app/core/services/culture.service';
import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/core/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from 'src/app/layout/themes/theme.service';
import { Language } from 'src/app/core/models/language.model';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss', '../shared.scss']
})
export class LoginErrorComponent implements OnInit {

  constructor(private loginService: LoginService,
              private cultureService: CultureService,
              private themeService: ThemeService,
              private translate: TranslateService) { }

  errorMessage: string;
  language: Language;

  ngOnInit(): void {
    this.language = this.cultureService.currentLanguage;

    this.translate.reloadLang(this.language.code).subscribe(() => {
      this.errorMessage = this.loginService.error && this.loginService.error.message ?
                          this.loginService.error.message :
                          this.loginService.error || this.translate.instant('common.error_unknown_code', {code: 0});
    });
  }

  get headerLogoSrc() {
    return this.themeService.getProperty('login-header-logo');
  }

  get footerLogoSrc() {
    return this.themeService.getProperty('login-footer-logo');
  }

  retryClick() {

  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '../models/language.model';

@Pipe({
    name: 'languagesFilter',
    pure: false
})
export class LanguagesFilterPipe implements PipeTransform {
    transform(items: Language[], language: Language): any {
        if (!items || !language) {
            return items;
        }
        // filter languages array, The given language will be filtered out        
        return items.filter(item => item.code != language.code);
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from './logging.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from './../../../environments/environment';
import { tap } from 'rxjs/operators';
import { UserInfo } from '../models/user-info.model';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private  http: HttpClient, private logger: LoggingService) { }

  private _current: UserInfo;
  private _userInfoSet = new BehaviorSubject<UserInfo>(null);

  get(): Observable<UserInfo> {
    if (this._current) {
      return of(this._current);
    } else {
      return this.update();
    }
  }

  update(): Observable<UserInfo> {
    const url = environment.authenticationUrl + `/api/userinfo`;

    return this.http.get<UserInfo>(url).pipe(
      tap (
        data => {
          this._current = data;
          this._userInfoSet.next(data);
          this.logger.debug('Retrieved userinfo: ' + JSON.stringify(data));
          this.logger.setAuthenticatedUserId(data.sub);
        },
        error => this.logger.error(error)
      )
    );
  }

  onUserInfoSet(): Observable<UserInfo> {    
    return this._userInfoSet;
  }
}

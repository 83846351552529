import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EndlessScrollRendererComponent } from './components/endless-scroll-renderer/endless-scroll-renderer.component';
import { PinEntryComponent } from './components/pin-entry/pin-entry.component';
import { MatCardModule } from '@angular/material/card';
import { InlineMessageComponent } from './components/inline-message/inline-message.component';
import { MatIconModule } from '@angular/material/icon';
import { JsonFormsModule } from '@jsonforms/angular';
import { JsonFormComponent } from './json-form/json-form.component';
import { VismaUnifiedDesignModule } from '@vismaux/ngx-vud';
import { VudJsonformsRenderersModule } from 'vud-jsonforms-renderers';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [    
    EndlessScrollRendererComponent,    
    PinEntryComponent,    
    InlineMessageComponent,    
    JsonFormComponent
  ],  
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,    
    MatRadioModule,
    MatCardModule,
    MatIconModule,    
    FlexLayoutModule,
    TranslateModule,    
    JsonFormsModule,
    VismaUnifiedDesignModule,
    VudJsonformsRenderersModule,    
    NgxSkeletonLoaderModule,
    MatProgressSpinnerModule
  ],  
  exports: [
    CommonModule,
    RouterModule,    
    PinEntryComponent,
    TranslateModule,
    EndlessScrollRendererComponent,
    InlineMessageComponent,
    JsonFormComponent
  ]
})
export class SharedModule { }

import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TokenResult } from '../models/token-result.model';
import { LoggingService } from './logging.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpAuthorizationInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private logger: LoggingService, private router: Router) { }

  inflightAuthRequest: Observable<any>;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // exempt some paths from authentication
    if (request.headers.get('authExempt') === 'true') {
      return next.handle(request);
    }

    const token = this.authService.accessToken;

    if (token && request.responseType !== 'blob') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError(error => {

        if (error.status === 404 && request.url.endsWith('/token')) {
          this.logger.debug('... 404 from token endpoint so refresh token expired. Signout and login again');
          this.authService.signOut(false);
          this.router.navigate(['/loginend']);
        } else if (error.status === 401) {

          this.logger.warn('Request resulted in 401. Attempting to refresh the access token.');

          // check if the response is from the token refresh endpoint
          // or the token end point
          if (request.url.endsWith('/token') || request.url.endsWith('/login')) {
            this.logger.debug('... 401 original request from /token or /login so signout. url: ' + request.url);

            this.authService.signOut();
            return throwError(error);
          }

          if (!this.inflightAuthRequest) {
            this.logger.debug('... inflightAuthRequest not set');

            this.inflightAuthRequest = this.authService.refresh();

            if (!this.inflightAuthRequest) {
              this.logger.warn('... unexpected, inflightAuthRequest not set, signout');
              this.authService.signOut();
              return throwError(error);
            }
          }

          return this.inflightAuthRequest.pipe(
            switchMap((tokenResult: TokenResult) => {
              // unset inflight request
              this.inflightAuthRequest = null;

              this.logger.debug('... performing inflightAuthRequest.');

              // clone the original request
              const authReqRepeat = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${tokenResult.access_token}`
                }
              });

              this.logger.debug('... performing original request. ' + authReqRepeat.url);

              return next.handle(authReqRepeat);
            })
          );
        } else {
          // Not a 401 or 404
          return throwError(error);
        }
    }));
  }
}

export const HttpAuthorizationInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpAuthorizationInterceptor,
  multi: true,
};

import { Injectable } from '@angular/core';
import { OTPInfo } from '../models/otp-info.model';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor() { }

  otpInfo: OTPInfo;

  setOtpInfo(otpInfo: OTPInfo) {
    this.otpInfo = otpInfo;
    sessionStorage.setItem('otp', JSON.stringify(otpInfo));
  }

  getOtpInfo(): OTPInfo {
    if (this.otpInfo) {
      return this.otpInfo;
    }

    const infoString = sessionStorage.getItem('otp');
    if (infoString) {
      try {
        return JSON.parse(infoString);
      } catch {
        return null;
      }
    }

    return null;
  }

  clearOtpInfo() {
    this.otpInfo = null;
    sessionStorage.removeItem('otp');
  }
}

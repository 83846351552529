import { Component, Input, Output, EventEmitter, AfterViewInit, forwardRef, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-pin-entry',
  templateUrl: './pin-entry.component.html',
  styleUrls: ['./pin-entry.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PinEntryComponent),
    multi: true
  }]
})
export class PinEntryComponent implements AfterViewInit, ControlValueAccessor  {

  constructor() {}

  charEntries: string[];
  pinForm: FormGroup;
  validationRegex: RegExp;
  singleCharRegex: RegExp;
  value = '';
  myLength: number;

  @Input()
  set codeLength(length: number) {
    this.myLength = length;
    this.initForm(length);
  }

  @Input()
  set codeRegex(regex: string) {
    this.validationRegex = new RegExp(regex);
  }

  @Input()
  set charRegex(regex: string) {
    this.singleCharRegex = new RegExp(regex);
  }

  @Input() invalidState: boolean;

  @Input() 
  set signalSetFocus(dummy: any) {
    // Set focus to first input
    if (this.numberInputs) {
      this.numberInputs.first.nativeElement.focus();
    }
  }

  @Output()
  changed: EventEmitter<any> = new EventEmitter();

  @Output()
  enter: EventEmitter<any> = new EventEmitter();

  /* Get handle on numberInput tags in the template */
  @ViewChildren('numberInput') numberInputs:QueryList<ElementRef>;

  onChange = (_: any) => {};

  initForm(length: number) {
    this.pinForm = new FormGroup({});

    this.charEntries = [];

    for (let i = 0; i < length; i++) {
      const formController: FormControl = new FormControl('', [Validators.required]);
      formController.setValue('');
      this.pinForm.addControl('codeField' + i, formController);
      this.charEntries.push('codeField' + i);
    }
  }

  ngAfterViewInit() {
    // make sure input is selected on focus
    document.querySelectorAll('.pin-input').forEach(element => element.addEventListener('focus', function() {
      this.select();
    }));

    const input: HTMLElement = document.querySelectorAll('.pin-input').item(0) as HTMLElement;
    input?.focus();

    // this.numberInputs.forEach(input => input.nativeElement.addEventListener('keyup', console.info('Keyup: ')));
  }

  onKeyUp($event: any, index: any) {
    let v_index;

    // Handle enter
    if ($event.keyCode === 13) {
      this.enter.emit();
    }

    // Set cursor to next input field (or previous if backspace)
    if ($event.keyCode === 8) {
      v_index = index === 0 ? 0 : index - 1;
      this.pinForm.controls[this.charEntries[index]].setValue('');
    } else {
      const inputString = $event.currentTarget.value;
      
      // Handle each entered character
      for (var i = 0; i < inputString.length; i++) {
        // Skip invalid characters
        if (!this.singleCharRegex || this.singleCharRegex.test(this.charEntries[index])) {
          this.pinForm.controls[this.charEntries[index]].setValue(inputString[i]);
          index = index === this.myLength - 1 ? this.myLength - 1 : index + 1;
        }        
      }
      
      v_index = index;
    }

    // Focus next input element
    const input: HTMLElement = document.querySelectorAll('.pin-input').item(v_index) as HTMLElement;
    input?.focus();

    let pinCodeValue = '';
    Object.keys(this.pinForm.value).forEach((key) => {
      pinCodeValue += this.pinForm.value[key];
    });

    this.value = pinCodeValue;

    this.emitChanges();

    // Validate input 
    if (this.validationRegex) {
      const isValid = this.validationRegex.test(pinCodeValue);

      if (this.changed) {
        this.changed.emit({value: pinCodeValue, valid: isValid});
      }
    } else {
      this.changed.emit({value: pinCodeValue});
    }
  }

  onKeyDown($event: any) {
    // reset invalid state
    this.invalidState = false;

    // Validate char entry
    if ($event.keyCode !== 13 && this.singleCharRegex) {
      if (!this.singleCharRegex.test($event.key)) {
        return false;
      }
    }
  }

  emitChanges() {
    this.onChange(this.value);
  }

  writeValue(val: any): void {
    for (let i = 0; i < this.charEntries.length; i++) {
      this.pinForm.controls[this.charEntries[i]].setValue(this.getValueWithIndex(val, i));
    }

    this.value = val;
  }

  getValueWithIndex(val: any, i: number): string {
    if (!val || !val.length || i >= val.length) {
      return '';
    }

    return val[i];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    document.querySelectorAll('.pin-input')
      .forEach(element => {
        if (isDisabled) {element.setAttribute('disabled', 'true');
      } else {
        element.removeAttribute('disabled');
      }
    });
  }

}

import { Task } from './task.model';
import { TaskUnknown } from './task-unknown.model';
import { TaskDocumentRead } from './task-document-read.model';
import { TaskDocumentDownload } from './task-document-download.model';
import { TaskDocumentApprove } from './task-document-approve.model';
import { TaskDocumentSign } from './task-document-sign.model';
import { TaskDocumentFill } from './task-document-formfill.model';

export class TaskHelper {

    static DocumentTaskFromAny(tasks: any): Task[] {
        const result = [];

        if (!tasks) {
            return result;
        }

        // Put tasks in correct order in array

        if (tasks.fill) {
            result.push(new TaskDocumentFill(!!tasks.fill.isCompleted))
        }
        
        if (tasks.read) {
            result.push(new TaskDocumentRead(!!tasks.read.isCompleted, tasks.read.readFirstPage, tasks.read.readLastPage));
        }

        if (tasks.download) {
            result.push(new TaskDocumentDownload(!!tasks.download.isCompleted));
        }

        if (tasks.sign) {
            result.push(new TaskDocumentSign(!!tasks.sign.isCompleted));
        }

        if (tasks.approve) {
            result.push(new TaskDocumentApprove(!!tasks.approve.isCompleted));
        }

        return result;
    }
}

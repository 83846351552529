import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/layout/themes/theme.service';

@Component({
  selector: 'app-login-end',
  templateUrl: './login-end.component.html',
  styleUrls: ['./login-end.component.scss', '../shared.scss']
})
export class LoginEndComponent implements OnInit {

  constructor(private themeService: ThemeService) { }

  ngOnInit(): void {
  }

  get headerLogoSrc() {
    return this.themeService.getProperty('login-header-logo');
  }

  get footerLogoSrc() {
    return this.themeService.getProperty('login-footer-logo');
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoggingService } from 'src/app/core/services/logging.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OTPInfo } from 'src/app/core/models/otp-info.model';
import { OtpService } from 'src/app/core/services/otp.service';
import { LoginService } from 'src/app/core/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from 'src/app/layout/themes/theme.service';

@Component({
  selector: 'app-login-code',
  templateUrl: './login-code.component.html',
  styleUrls: ['./login-code.component.scss', '../shared.scss']
})
export class LoginCodeComponent implements OnInit {

  constructor(private authService: AuthService,
              private logger: LoggingService,              
              private themeService: ThemeService,
              private otpService: OtpService,
              private loginService: LoginService,
              private translate: TranslateService,
              private router: Router) { }

  codeForm: FormGroup;
  otpInfo: OTPInfo;
  invalidCode: boolean;
  checkFailed: boolean;
  beingCalled: boolean;
  busy: boolean;
  errorMessage: string;
  infoMessage: string;
  focusPinEntry: any;

  ngOnInit(): void {
    // If user is authenticated goto application (/inbox)
    // Login not needed
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/inbox']);
      return;
    }

    // Check if otp info is present
    this.otpInfo = this.otpService.getOtpInfo();
    if (!this.otpInfo) {
      // otpInfo not available -> unexpected error
      this.loginService.redirectToError(this.translate.instant('common.error_unknown_code'));
      return;
    }

    this.codeForm = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)])
    });
  }

  get headerLogoSrc() {
    return this.themeService.getProperty('login-header-logo');
  }

  get footerLogoSrc() {
    return this.themeService.getProperty('login-footer-logo');
  }

  onCallMeClick() {
    // If busy: skip
    if (this.busy) {
      return;
    }

    this.logger.logEvent('LogInCallMeClicked', {invite: this.otpInfo.invitation_code})

    this.busy = true;

    // Send the otp by voice
    this.authService.otpSend(this.otpInfo, 'voice').subscribe(data => {
      // Update otpInfo (attempts have changed)
      this.showInfoMessage(this.translate.instant('login.info_beingcalled', {phone: this.otpInfo.phone_number}));
      this.otpInfo = data;
      this.focusPinEntry = {}; // signal to set focus to code entry
      this.otpService.setOtpInfo(this.otpInfo);
      this.busy = false;
    }, error => {
      // Create OTP failed
      // Possible errors: Status429TooManyRequests, Status400BadRequest
      this.logger.logException(new Error(`LogInCallme failed for invite: ${this.otpInfo.invitation_code}. ${error.message}`));

      this.busy = false;

      if (error.status === 429) {
        this.loginService.redirectToError(error.error?.message || this.translate.instant('login.error_invitationtoooften'));
      } else {
        this.showErrorMessage(error.error?.message || this.translate.instant('error_cannotcall', {code: error?.status}));
      }
    });
  }

  onCheckCodeClick() {
    this.busy = true;
    this.beingCalled = false;
    this.checkFailed = false;

    this.codeForm.controls.code.disable();

    this.authService.otpVerify(this.otpInfo.id, this.codeForm.get('code').value).subscribe(data => {
      // data.is_valid == true => proceed with authorization_code
      // data.is_valid == false => update otpInfo and update dialog. Redirect to error page if verify_attempts_available == 0

      if (data.is_valid) {
        this.logger.debug('Code is valid, continue');
        this.authService.registerAuthorizationCode(data.authorization_code).subscribe(() => {
          this.otpService.clearOtpInfo();
          // proceed to application
          this.router.navigate(['/inbox']);
        });
      } else {
        this.logger.warn('Code is invalid! Attempts left: ' + data.verify_attempts_available);

        this.logger.logEvent('LogInInvalidCode', {invite: this.otpInfo.invitation_code, attemptsLeft: data.verify_attempts_available});

        this.otpInfo.verify_attempts_available = data.verify_attempts_available;
        this.otpService.setOtpInfo(this.otpInfo);

        if (data.verify_attempts_available > 0) {
          this.checkFailed = true;

          this.showErrorMessage(this.translate.instant('login.error_invalidcode', {count: data.verify_attempts_available}));
        } else {
          this.loginService.redirectToError(this.translate.instant('login.error_invalidcodestop'));
        }

        this.busy = false;
      }
      
      this.codeForm.controls.code.enable();
    }, error => {
      // Too many attempts?

      this.logger.logException(new Error(`LogInCheckCode failed for invite: ${this.otpInfo.invitation_code}. ${error.message}`));

      this.loginService.redirectToError(error.error?.message || this.translate.instant('login.error_unknown_code', {code: error?.status}));
    });
  }

  onCodeChanged(result) {
    this.invalidCode = false;
    this.checkFailed = false;
  }

  showInfoMessage(message: string) {
    this.infoMessage = message;
    this.errorMessage = null;
  }

  showErrorMessage(message: string) {
    this.infoMessage = null;
    this.errorMessage = message;

  }

  clearMessages() {
    this.infoMessage = null;
    this.errorMessage = null;
  }

}

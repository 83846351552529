import { Injectable } from '@angular/core';
import * as UAParser from 'ua-parser-js';

@Injectable({
  providedIn: 'root'
})
export class UserAgentService {
  
  constructor() {
    this.parser = new UAParser();
  }

  parser: any;

  getBrowserName(): string {
    const result = this.parser.getResult();
    return result.browser.name;
  }
  
  getOperatingSystemName(): string {
    const result = this.parser.getResult();
    return result.os.name;
  }
  
  getDeviceType(): string {
    const device = this.parser.getDevice();
    return device.type;
  }
}


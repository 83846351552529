<div *ngIf="!_schema">
    <ngx-skeleton-loader class="skeleton" count="1" [theme]="{ height: '48px', 'margin': '20px 0' }"></ngx-skeleton-loader>    
    <ngx-skeleton-loader class="skeleton" count="5" [theme]="{ height: '24px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader class="skeleton" count="1" [theme]="{ height: '48px', 'margin': '20px 0' }"></ngx-skeleton-loader>    
    <ngx-skeleton-loader class="skeleton" count="5" [theme]="{ height: '24px' }"></ngx-skeleton-loader>
</div>
<div *ngIf="_schema">
    <jsonforms           
        #jsonForm
        [data]="_startData"
        [schema]="_schema"
        [uischema]="_uiSchema"
        [renderers]="renderers"
        [i18n]="i18nObj"
        [ajv]="ajv"
        (dataChange)="formChange($event)"
        (errors)="onErrors($event)"></jsonforms>
    <button type="button" class="btn" [disabled]="submitting" (click)="onCancel()">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="submitting" (click)="onSubmit()">Submit</button>
    <mat-progress-spinner *ngIf="submitting" class="spinner" mode="indeterminate" diameter="32" ></mat-progress-spinner>
    <app-inline-message *ngIf="errorMessage && !submitting" class="message" [message]='errorMessage' type='error'></app-inline-message>
</div>
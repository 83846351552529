import { Injectable, Inject, EventEmitter } from '@angular/core';
import { THEMES, ACTIVE_THEME, Theme } from './symbols';


@Injectable()
export class ThemeService {

  themeChange = new EventEmitter<Theme>();

  constructor(
    @Inject(THEMES) public themes: Theme[],
    @Inject(ACTIVE_THEME) public themeName: string
  ) {}

  getTheme(name: string): Theme {
    const theme = this.themes.find(t => t.name === name);
    
    if (!theme) {
      return null;
    }

    return this.consolidateTheme(theme);
  }

  getActiveTheme(): Theme {
    if (this.themeName) {
      return this.getTheme(this.themeName);
    }
  }

  getDynamicThemeName(dynamicThemes: Array<Array<string>>): string {
    if (!dynamicThemes) {
      return null;
    }

    // Try to match the current hostname with a dynamic theme (case insensitive)   
    const location = window.location.href.toUpperCase();
    for (const theme of dynamicThemes) {
      const urlPattern = theme[0].toUpperCase();
      if (location.indexOf(urlPattern) >= 0) {
        return theme[1];
      }
    }
    
    return null;
  }

  getProperty(propName: string) {
    return this.getActiveTheme().properties[propName];
  }

  setTheme(name: string) {
    this.themeName = name;
    this.themeChange.emit( this.getActiveTheme());
  }

  registerTheme(theme: Theme) {
    this.themes.push(theme);
  }

  consolidateTheme(theme: Theme): Theme {
    if (theme.base) {
      const base = this.getTheme(theme.base);
      if (base) {
        theme.properties = {
          ...base.properties,
          ...theme.properties
        };
      }
    }

    return theme;
  }

  updateTheme(name: string, properties: { [key: string]: string; }) {
    const theme = this.getTheme(name);
    theme.properties = {
      ...theme.properties,
      ...properties
    };

    if (name === this.themeName) {
      this.themeChange.emit(theme);
    }
  }

}

import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from '../models/login.model';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TokenResult } from '../models/token-result.model';
import { tap, share, map } from 'rxjs/operators';
import { LoggingService } from './logging.service';
import { InvitationInfo } from '../models/invitation-info.model';
import { OTPInfo } from '../models/otp-info.model';
import { UrlDomainService } from './url-domain.service';

const AUTH_PASSWORDLESS: string = "passwordless";
const AUTH_NORMAL: string = "normal";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  constructor(
    private  http: HttpClient,
    private router: Router,
    private logger: LoggingService, 
    private urlDomainService: UrlDomainService) {
    }

  accessToken: string;

  isAuthenticated(): boolean {
    const login = this.getLogin();
    return !!login.refresh_token;
  }

  initiateInvitedLogin(invitationCode: string): Observable<InvitationInfo> {
    let url = environment.authenticationUrl + `/api/invitations/${invitationCode}`;

    // Add domain
    const myDomain = this.urlDomainService.getMyDomain();
    if (myDomain) {
      url += '?domain=' + encodeURIComponent(myDomain);
    }

    return this.http.get<any>(url).pipe(
      tap (
        data => {
          this.logger.info('Successfully requested invitation info. Result: ' + JSON.stringify(data))
        }, error => {
          this.logger.error(error);
        })
      );
  }

  createOtp(invitationCode: string): Observable<OTPInfo> {
    const url = environment.authenticationUrl + '/api/otp';

    const postData = { invitation_code: invitationCode };

    return this.http.post<OTPInfo>(url, postData).pipe(
      tap (
        data => this.logger.info('Successfully created OTP. Result: ' + JSON.stringify(data)),
        error => this.logger.error(error)
      )
    );
  }

  otpSend(otpInfo: OTPInfo, transport: string): Observable<OTPInfo> {
    const url = environment.authenticationUrl + `/api/otp/${otpInfo.id}/$send`;

    const postData = { transport };

    return this.http.post<any>(url, postData).pipe(
      map(data => {
        otpInfo.send_sms_attempts_available = data.send_sms_attempts_available;
        otpInfo.send_voice_attempts_available = data.send_voice_attempts_available;
        return otpInfo;
      }),
      tap (
        data => this.logger.info('Successfully send OTP. Result: ' + JSON.stringify(data)),
        error => this.logger.error(error)
      )
    );
  }

  otpVerify(id: string, code: string): Observable<any> {
    const url = environment.authenticationUrl + `/api/otp/${id}/$verify`;

    const postData = { code };

    return this.http.post<any>(url, postData).pipe(
      tap (
        data => this.logger.info('Successfully send OTP. Result: ' + JSON.stringify(data)),
        error => this.logger.error(error)
      )
    );
  }

  registerAuthorizationCode(code: string): Observable<any> {
    const url = environment.authenticationUrl + '/api/token';

    const postData = {
      grant_type: 'authorization_code',
      code: code
    };

    this.logger.debug('Retrieving token using the authorization code.');

    return this.http.post<any>(url, postData).pipe(
      tap (
        data => {
          this.logger.info('Successfully retrieved token with authorization code. User authenticated');
          this.accessToken = data.access_token;
          this.updateLogin(data.refresh_token);
        },
        error => this.logger.error(error)
      )
    );
  }  

  getLogin(): Login {
    const loginString = sessionStorage.getItem('login');

    if (loginString) {
      return JSON.parse(loginString);
    }

    return new Login();
  }

  updateLogin(refreshToken: string): Login {
    const login = this.getLogin();
    login.refresh_token = refreshToken;
    sessionStorage.setItem('login', JSON.stringify(login));
    return login;
  }

  logoff(): Observable<any> {
    const url = environment.authenticationUrl + '/api/revoke';

    const login = this.getLogin();

    const postData = {
      token: login.refresh_token
    };

    this.logger.debug('About to revoke the refresh token.');

    return this.http.post<any>(url, postData).pipe(
      tap (
        data => {
          this.logger.info('Successfully revoked refresh token');
          this.signOut();
        },
        error => this.logger.error(error)
      )
    );
  }

  refresh(): Observable<TokenResult> {
    // Get the refresh token for the user
    const user = this.getLogin();

    if (!user || !user.refresh_token) {
      throw Observable.throw('Cannot refresh, user not authenticated');
    }

    // Refresh the tokens
    const url = environment.authenticationUrl + `/api/token`;

    this.logger.debug('About to post to: ' + url);

    const postData = {
      grant_type: 'refresh_token',
      client_id: environment.authenticationClientId,
      refresh_token: user.refresh_token
    };

    return this.http.post<TokenResult>(url, postData).pipe(
      share(), // To prevent several update requests being sent simultaniously
      tap (
        data => {
          // Store new refreshToken and accessToken
          this.accessToken = data.access_token;
          this.updateLogin(data.refresh_token);

          this.logger.info('Successfully updated authentication tokens.');
        },
        error => this.logger.error(error)
      ));
  }

  signOut(withRedirect: boolean = true) {
    sessionStorage.removeItem('login');
    this.accessToken = null;

    // Will show empty login form
    if (withRedirect) {
      this.router.navigate(['/loginend']);
    }
  }

  authenticateWithToken(token: string) {
    this.logger.debug('Authenticating with token: ' + token);

    const jwtPayload = this.parseJwt(token);

    // Store dummy user
    const login = new Login();
    login.refresh_token = token;
    login.authenticatedWithToken = true;
    sessionStorage.setItem('login', JSON.stringify(login));
    this.accessToken = token;

    //this.logger.setAuthenticatedUserId(login.email);
  }

  handleTokenAccess() {
    const user = this.getLogin();

    if (user && user.authenticatedWithToken) {
      this.accessToken = user.refresh_token;
    }
  }

  private parseJwt (token): any {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
}

import { Task } from './task.model';

export class TaskDocumentRead extends Task {

    constructor(isCompleted: boolean, readFirstPage: boolean, readLastPage: boolean) {
        super('read', isCompleted);

        this.readFirstPage = readFirstPage;
        this.readLastPage = readLastPage;
    }

    readFirstPage: boolean;
    readLastPage: boolean;
}

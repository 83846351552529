import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonSchema } from '@jsonforms/core';
import { forkJoin, Observable, of } from 'rxjs';
import { LoggingService } from './logging.service';
import { environment } from './../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { FormDefinition } from '../models/form-definition.model';
import { TaskDocumentFill } from '../models/task-document-formfill.model';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private  http: HttpClient, 
    private logger: LoggingService) { }

  getForm(envelopeId: string, documentId: string): Observable<FormDefinition> {
    const url = environment.routingApiUrl + `/api/inbox/${envelopeId}/documents/${documentId}/form`;
    this.logger.debug('About to call (get): ' + url);

    return this.http.get<any>(url).pipe(
      map (item => {
       return item;
      }),
      tap (
        data => this.logger.debug(JSON.stringify(data)),
        error => this.logger.error(error)
      )
    );
  }  

  fillForm(envelopeId: string, documentId: string, formData: any): Observable<TaskDocumentFill> {
    const url = environment.routingApiUrl + `/api/inbox/${envelopeId}/documents/${documentId}/form/$fill`;
    this.logger.debug('About to call (post): ' + url);

    return this.http.post<any>(url, formData).pipe(
      tap (
        data => this.logger.debug(JSON.stringify(data)),
        error => this.logger.error(error)
      )
    );
  }
}

<div class="login-container" fxLayoutAlign.gt-xs="center center">
    <div class="login-dialog" ngClass.gt-xs="floating" fxFlex fxLayout="column" role="main">    
        <div class="header" fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
            <img class="image" alt="Digital signing" src="{{headerLogoSrc}}">            
        </div>    
        <div fxFlex class="title" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
            <h1 class="title-text" translate>login.title</h1>
        </div>
        <div class="form" fxFlex="grow" fxLayout="column" class="form" fxLayoutGap="12px" fxLayoutAlign="start start"> 
            <div class="content" fxLayout="column" fxLayoutGap="12px">            
                <h2 translate>login.error_title</h2>
                <app-inline-message *ngIf="errorMessage" class="message" [message]='errorMessage' type='error'></app-inline-message>                
                <!-- <button fxFlex mat-raised-button class="button" (click)="retryClick()">Probeer opnieuw</button> -->
            </div>
        </div>        
        <div fxFlex class="footer" fxLayoutAlign="center center">
            <img class="image" alt="IntraData logo" src="{{footerLogoSrc}}">
        </div>       
    </div>
</div>

import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, of, from, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, delay } from 'rxjs/operators';
import { AuthService } from './auth.service';

export const isMock = environment.mock;

@Injectable({
  providedIn: 'root'
})
export class HttpMockInterceptorService {

  constructor(private injector: Injector, private http: HttpClient, private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Onyl continue if mocking is enabled
    // and skip requests to mock data itself
    if (isMock && request.url.indexOf('assets/mock') === -1 && request.url.indexOf('/api/') !== -1) {
      let url: string;
      const invitationCode = this.authService.getLogin()?.refresh_token;
      
      if (request.url.indexOf('/thumbnails/') !== -1) {
        // transform thumnail request
        const splitted = request.url.split('/');
        const idx = splitted.indexOf('thumbnails');
        const docId = splitted[idx - 1];
        
        const widthIdx = splitted[idx + 1].indexOf('?width=');
        const pageIndex = parseInt(splitted[idx + 1].slice(0, widthIdx));
        const reqWidth = parseInt(splitted[idx + 1].substr(widthIdx + 7));
        url = (environment.mockBasePath || '') + 'assets/mock/'+ invitationCode + '/thumbnails/' + docId + '_' + pageIndex + '.png';
        const pagesUrl = (environment.mockBasePath || '') + 'assets/mock/' + invitationCode + '/thumbnails/' + docId + '.json';

        request = request.clone({url: url});
        return forkJoin([next.handle(request), this.http.get<any>(pagesUrl)]).pipe(
          map(([resp, pages]) => {
            // set response headers using the information from the call to pages
            if (resp instanceof HttpResponse) {
              const ratio = pages[pageIndex - 1].height / pages[pageIndex - 1].width;
              const reqHeight = Math.round(ratio * reqWidth);

              //console.info(`Mock thumbnail width:${reqWidth}; height:${reqHeight}` );

              resp = resp.clone({
                headers: resp.headers.set('x-page-index', pageIndex.toString())
                  .set('x-page-count', pages.length.toString())
                  .set('x-width', reqWidth.toString())
                  .set('x-height', reqHeight.toString())                
              });

            }
            
            return resp;
          })
        );
      } else if (request.url.indexOf('/pages') !== -1) {
        // transform pages request
        const splitted = request.url.split('/');
        const idx = splitted.indexOf('pages');
        url = (environment.mockBasePath || '') + 'assets/mock/' + invitationCode + '/thumbnails/' + splitted[idx - 1] + '.json';
      } else {
        // transform api request
        url = this.getMockUrl(request);
      }
      
      console.log(`Mock data. Request for ${request.url}. Returned: ${url}`);
      
      let postData: any;
      if (request.method == "POST") {
        postData = request.body;
      }

      return this.http.get(url).pipe(
        delay(500),
        map(data => {
          let headers = new HttpHeaders();

          if (url.includes('/thumbnails/')) {
            headers = new HttpHeaders({'Location': '../../../assets/mock/thumbnail.png'});
            //headers.append('Location', '../../../assets/documents');
          }
          return new HttpResponse({ status: 200, body: data, headers });          
        })
      );      
    }
    
    return next.handle(request);
  }

  getMockUrl(request: HttpRequest<any>): string {
    const urlBase = environment.mockBasePath?.length > 0 ? environment.mockBasePath + 'assets/mock' : '../../../assets/mock';
    const urlEnd = request.url.substr(request.url.indexOf('/api/') + 4);

    //
    if (urlEnd === '/otp') {
      const auth_code = request.body.invitation_code;
      return urlBase + '/otp/' + auth_code + '/otp.json';
    } else if (urlEnd === '/token') {
      const code = request.body.code;
      return urlBase + '/token/' + code + '/token.json';
    } else {
      const loginString = sessionStorage.getItem('login');
      if (loginString) {
        const refreshToken = JSON.parse(loginString)?.refresh_token;
        if (refreshToken) {
          return urlBase + '/' + refreshToken + urlEnd + '.json';
        }
      }
    } 

    return urlBase + urlEnd + '.json';
  }
}

export const HttpMockInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpMockInterceptorService,
  multi: true,
};

import { Theme } from './symbols';


export const defaultTheme: Theme = {
    name: 'defaultTheme',
    base: null,
    properties: {
        '--font-family': '"Open Sans", sans-serif',
        '--default-text-color': '#303030',
        '--default-link-color': '#383f83',
        '--disabled-link-color': '#979AAF',
        '--background-color': '#c2c3ca',
        '--header-left-color': '#3C4DA8',
        '--header-middle-color': '#3C4DA8',
        '--header-right-color': '#000000',
        '--header-text-color': '#FFFFFF',
        '--header-height': '80px',
        '--header-small-height': '54px',
        '--subheader-left-color': '#FFFFFF',
        '--subheader-right-color': '#FFFFFF',
        '--soft-text-color': '#777777',
        '--box-background-color': '#EEEEEE',
        '--box-card-background-color': '#FFFFFF',
        '--box-card-border-color' : '#BBBBBB',
        '--box-card-text-color': '#303030',
        '--box-card-errortext-color': '#ff5722',
        '--box-card-warning-color': '#ffa500',
        '--button-shadow': '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
        '--inbox-card-shadow': '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        '--button-border': 'none',
        '--inbox-card-border': 'none',
        '--inbox-container-color': '#606060',
        '--inbox-background-color': '#faebd7ad',
        '--inbox-item-background-color': '#FFFFFF',
        '--inbox-text-color': '#333333',
        '--inbox-document-completed-border-color': '#008000',
        '--inbox-document-completed-hover-color': '#C2F0C7',
        '--inbox-document-border-color': '#ffa500',
        '--inbox-document-hover-color': '#faebd7',
        '--donebox-background-color': '#d8d3d096',
        '--donebox-item-background-color': '#BBBBBB',
        '--donebox-text-color': '#333333',
        '--donebox-document-border-color': '#3163b3',
        '--donebox-document-hover-color': '#c7d3f8',        
        '--logo-width': '200px',
        '--logo-small-width': '120px',        
        '--warning-color': '#ffa500',
        '--info-color': '#255ec7',
        '--button-accent-color': '#008000',
        '--button-login-normal-background-color': '#008000',
        '--button-login-disabled-background-color': '#99C35D',
        '--button-login-disabled-text-color': '#CFE5AE',
        '--color-green': '#008000',
        '--error-color': '#E94454',
        '--spinner-color': '#383f83',
        'logo': 'assets/images/Krabbl.svg',
        'login-header-logo': 'assets/images/ID_icon_digitaleondertekening-01.png',
        '--login-header-logo-width': '80px',
        'login-footer-logo': 'assets/images/Digital_Visma_logo.png'
    }
  };

import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from './../../../environments/environment';
import { Router, RouterEvent, NavigationStart, ResolveEnd, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private router: Router) { 
    this.logToConsole = !!environment.consoleLogging;

    if (environment.appInsights_InstrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsights_InstrumentationKey          
        }
      });
      this.appInsights.loadAppInsights();

      this.router.events.subscribe(
        (event: RouterEvent) => {
            if (event instanceof NavigationStart) {
                this.appInsights.startTrackEvent("PAGE LOAD TIME");
            }

            if (event instanceof ResolveEnd) {
                const activatedComponent = this.getActivatedComponent(
                    event.state.root
                );

                if (activatedComponent) {
                    this.logPageView(this.getPageName(event.urlAfterRedirects), event.urlAfterRedirects);
                }
            }

            if (event instanceof NavigationEnd) {
                this.appInsights.stopTrackEvent("PAGE LOAD TIME");
            }
        }
    );
    }
  }

  appInsights: ApplicationInsights;
  logToConsole: boolean;

  setAuthenticatedUserId(userId: string): void {
    if (this.appInsights) {
      this.appInsights.setAuthenticatedUserContext(userId);      
      this.debug('appInsights: setAuthenticatedUserId ' + userId);
    }
  }

  clearAuthenticatedUser() {
    if (this.appInsights) {
      this.appInsights.clearAuthenticatedUserContext();
    }
  }

  logPageView(name?: string, url?: string) { 
    if (this.appInsights) {
      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
      this.debug('appInsights: logPageView ' + name);
    }
  }

  startEvent(name?: string) {
    if (this.appInsights) {
      this.appInsights.startTrackEvent(name);
    }
  }

  stopEvent(name: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.stopTrackEvent(name, properties);
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackEvent({ name: name}, properties);
      this.debug('appInsights: logEvent ' + name);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.appInsights) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackTrace({ message: message}, properties);
    }
  }

  debug(message: string) {
    if (this.isLevelDebug) {
      console.info(message);
    }
  }

  info(message: string) {
    if (this.isLevelInfo) {
      console.info(message);
    }
  }

  warn(message: string) {
    if (this.isLevelWarn) {
      console.warn(message);
    }
  }

  error(message: string) {
    if (this.isLevelError) {
      console.error(message);
    }
  }

  private get isLevelDebug(): boolean {
    return (environment.logLevel && environment.logLevel.toUpperCase() === 'DEBUG');
  }

  private get isLevelInfo(): boolean {
    return (this.isLevelDebug || environment.logLevel && environment.logLevel.toUpperCase() === 'INFO');
  }

  private get isLevelWarn(): boolean {
    return (this.isLevelInfo || environment.logLevel && environment.logLevel.toUpperCase() === 'WARN');
  }

  private get isLevelError(): boolean {
    return (this.isLevelWarn || environment.logLevel && environment.logLevel.toUpperCase() === 'ERROR');
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
        return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getPageName(url: string): string {
    // strip paramaters from url
    if (url && url.length > 2) {
      if (url.startsWith('/')) {
        url = url.substr(1);
      }

      const idx = url.indexOf('/');
      if (idx > 0) {
        return url.substr(0, idx);
      }
    }

    return url;
  }

}

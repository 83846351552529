import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InboxItem } from '../models/inbox-item.model';
import { Observable, of } from 'rxjs';
import { environment } from './../../../environments/environment';
import { tap, map } from 'rxjs/operators';
import { LoggingService } from './logging.service';
import { UrlDomainService } from './url-domain.service';

@Injectable({
  providedIn: 'root'
})
export class InboxService {

  constructor(private  http: HttpClient, 
              private logger: LoggingService,              
              private urlDomainService: UrlDomainService) {
  }

  private _inboxItemsCache: InboxItem[];
  private _isDirty: boolean;

  setDirty() {
    this._isDirty = true;
  }

  getInboxItems(forceUpdate: boolean): Observable<InboxItem[]> {

    if (!this._isDirty && !forceUpdate && this._inboxItemsCache) {
      // this._inboxItems.forEach(inboxItem => {
      //   this.documentStateService.updateInboxItem(inboxItem);
      // });
      return of(this._inboxItemsCache);
    }

    let url = environment.routingApiUrl + '/api/inbox';

    // Add domain
    const myDomain = this.urlDomainService.getMyDomain();
    if (myDomain) {
      url += '?domain=' + encodeURIComponent(myDomain);
    }

    this.logger.debug('About to call (get): ' + url);

    return this.http.get<InboxItem[]>(url).pipe(
      map (data => {
        // Convert each given item to an InboxItem
        this._inboxItemsCache = data.map(item => {
          const inboxItem = new InboxItem(item.id, item.title, item.subtitle, item.dateScheduled, item.expirationDate, item.documents);
          //this.documentStateService.updateInboxItem(inboxItem);
          return inboxItem;
        });

        this._isDirty = false;
        return this._inboxItemsCache;
      }),
      tap (
        data => this.logger.debug(JSON.stringify(data)),
        error => this.logger.error(error)
      )
    );
  }

  getEnvelope(envelopeId: string): Observable<InboxItem> {
    const url = environment.routingApiUrl + `/api/inbox/${envelopeId}`;
    this.logger.debug('About to call (get): ' + url);

    return this.http.get<InboxItem>(url).pipe(
      map (item => {
        // Convert each given item to an InboxItem
        const inboxItem = new InboxItem(item.id, item.title, item.subtitle, item.dateScheduled, item.expirationDate, item.documents);
        //this.documentStateService.updateInboxItem(inboxItem);
        return inboxItem;
      }),
      tap (
        data => this.logger.debug(JSON.stringify(data)),
        error => this.logger.error(error)
      )
    );
  }

  rejectEnvelope(envelopeId: string, reason: string): Observable<any> {
    const url = environment.routingApiUrl + `/api/inbox/${envelopeId}/$reject`;
    this.logger.debug('About to call (post): ' + url);

    this._isDirty = true;

    return this.http.post<InboxItem>(url, { reason }).pipe(
      tap (
        data => this.logger.debug(JSON.stringify(data)),
        error => this.logger.error(error)
      )
    );
  }

  forwardEnvelope(envelopeId: string): Observable<any> {
    const url = environment.routingApiUrl + `/api/inbox/${envelopeId}/$forward`;
    this.logger.debug('About to call (post): ' + url);

    this._isDirty = true;

    return this.http.post<any>(url, { }).pipe(
      map (() => true),
      tap (
        data => this.logger.debug('$forward finished.'),
        error => this.logger.error(error)
      )
    );
  }

  setCacheDocumentTaskIsCompleted(envelopeId: string, documentId: string, taskCode: string, isCompleted: boolean) {
    
    if (!this._inboxItemsCache) {
      // Cache is empty, so we can't update it
      return;
    }

    const inboxItem = this._inboxItemsCache.find(item => item.id === envelopeId);
    if (inboxItem) {
      const document = inboxItem.documents.find(doc => doc.id === documentId);
      if (document) {
        document.setTaskCompleted(taskCode, isCompleted);        
      }
    }   
  }

  registerDownload(envelopeId: string, documentId: string): Observable<boolean> {
    const url = environment.routingApiUrl + `/api/inbox/${envelopeId}/documents/${documentId}/$register-download`;

    this.logger.debug('About to call (post): ' + url);

    this._isDirty = true;

    return this.http.post<boolean>(url, {}).pipe(
      tap (
        data => this.logger.debug(JSON.stringify(data)),
        error => this.logger.error(error)
      )
    );
  }

  areThereInboxItemsWithUncompletedTasks(): boolean {    
    if (!this._inboxItemsCache) {
      return false;
    }
    
    return this._inboxItemsCache.some(item => item.allTasksCompleted === false);
  }
}

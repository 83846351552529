import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { environment } from './../../../environments/environment';

const searchReplaceParser = new RegExp('(?<search>[^\/]*)\/(?<replace>[a-z0-9.$]+)');

interface SearchReplace {
  search: RegExp,
  replace: string
}

@Injectable({
  providedIn: 'root'
})
export class UrlDomainService {

  constructor(private logger: LoggingService,) { 
    // extract search-replace patterns from environment
    if (environment.url_to_domain) {
      const splitted: string[] = environment.url_to_domain.split(',');
      this.searchReplacePatterns = splitted.map(item => { 
        const parsed = item.match(searchReplaceParser);
        return {
          search: new RegExp(parsed.groups.search), 
          replace: parsed.groups.replace
        }
      });
    }
  }

  searchReplacePatterns: SearchReplace[];
  private myDomain: string;

  getMyDomain(): string {
    if (this.myDomain == null) {
      this.myDomain = this.determineMyDomain() || '';
    }

    return this.myDomain != '' ? this.myDomain : null;
  }

  private determineMyDomain(): string {
    if (!this.searchReplacePatterns) {
      return null;
    }

    let result: string = null;
    const myUrl = location.host;

    this.logger.debug('UrlDomainService - determine domain for url: ' + myUrl);

    for (let pattern of this.searchReplacePatterns) {
      const match = myUrl.match(pattern.search);

      this.logger.debug('UrlDomainService - matching ' + pattern.search.toString() + (match ? ' - Match found' : ' - No match'));

      if (match) {        
        result = match[0].replace(pattern.search, pattern.replace);

        this.logger.debug('UrlDomainService - Replace pattern: ' + pattern.replace + '; replace string: ' + match[0] + ';  result domain: ' + result);

        break;
      }
    }

    return result;
  }
}

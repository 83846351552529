import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { UserAgentService } from './user-agent.service';
import { UserInfoService } from './user-info.service';

@Directive({
  selector: '[appWootric]'
})
export class WootricDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef, 
    private translate: TranslateService, 
    private userInfoService: UserInfoService,
    private userAgentService: UserAgentService) {

    // Skip if in mock mode
    if (!environment.wootric?.accountToken) {
      return;
    }

    // Skip if wootric is already initialized
    if (window['wootricSettings']) {
      return;
    }

    userInfoService.onUserInfoSet().subscribe(userInfo => {
      if (userInfo) {
        if (environment.wootric?.forceSurvey) {
          window['wootric_no_surveyed_cookie'] = true; // Shows survey immediately for testing purposes.  TODO: Comment out for production.
          window['wootric_survey_immediately'] = true; // Bypass cookie based throttle for testing purposes.  TODO: Comment out for production.
        }
        window['wootricSettings'] = {
          email: userInfo.sub, 
          // created_at: session.timestamp, // Disabled to make Wootric take care of this date
          account_token: environment.wootric?.accountToken, //'NPS-37457c4d' // This is your unique account token.
          language: this.translate.currentLang,
          properties: {
            tenant: userInfo.tenant,
            domain: userInfo.domain,
            browser: this.userAgentService.getBrowserName(),            
            os: this.userAgentService.getOperatingSystemName(),
            device: this.userAgentService.getDeviceType(),
            language: this.translate.currentLang,
            environment: window.location.hostname
          }
        };
      }
      // console.info('Wootric initialized');
    });    
  }

  ngAfterViewInit() {
    if (!environment.wootric?.accountToken) {
      return;
    }

    var script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = 'https://cdn.wootric.com/wootric-sdk.js';
    script.async = true;
    script.onload = function() {
      window['WootricSurvey'].run();
    };

    this.elementRef.nativeElement.appendChild(script);
  }
}
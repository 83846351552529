<mat-toolbar color="primary" class="app-bar" ngClass.xs="small">
  <mat-toolbar-row>    
    <div fxFlex fxLayoutAlign="start center">
      <img class='logo' alt="Visma" src="{{logoSrc}}">
      <div fxFlex fxLayoutAlign="end center">
        <div *ngIf="userInfo" class="name">{{displayName}}</div>
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="languagesMenu"><img class="language-flag center" src="{{language.flag}}" ><span class="flag-title">{{language.title}}</span></button>
          <!-- <button mat-menu-item (click)="onVismaTheme()"><span>Visma theme</span></button>
          <button mat-menu-item (click)="onVismaDarkTheme()"><span>Visma Dark theme</span></button>
          <button mat-menu-item (click)="onVismaBeauTheme()"><span>Visma Dark PF Beau Sans Pro</span></button>
          <button mat-menu-item (click)="onKrabblTheme()"><span>Krabbl theme</span></button> -->
          <button mat-menu-item (click)="onSignOut()"><i class="fas fa-sign-out-alt fa-15x" ></i><span translate>header.menu_logoff</span></button>
        </mat-menu>
        <mat-menu #languagesMenu="matMenu">
          <button mat-menu-item *ngFor="let myLang of languages | languagesFilter:language" (click)="onLanguageSelect(myLang)" >
            <img class="language-flag center" src="{{myLang.flag}}" ><span class="flag-title">{{myLang.title}}</span>
          </button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="User menu">
            <i fxHide.xs="true" class="fas fa-user fa-2x"></i><i fxHide.gt-xs="true" class="fas fa-user fa-15x"></i>&nbsp;&nbsp;<i class="fas fa-caret-down fa-1x"></i>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

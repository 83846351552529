import { Theme } from './symbols';

export const vismaYouServe: Theme = {
    name: 'vismaYouServe',
    base: 'vismaRaet',
    properties: {       
        '--header-left-color': '#1a313b',
        '--header-middle-color': '#748595',
        '--header-right-color': '#1a313b',
        '--login-header-height': '68px',
        '--login-header-logo-align': 'start',
        '--login-header-logo-width': '240px',
        '--login-header-background-color': '#1a313b',
        '--login-header-logo-hor-align': 'start',
        '--login-header-logo-vert-align': 'center',
        '--button-login-normal-background-color': '#E5A23E',
        '--button-login-disabled-background-color': '#F2C480',
        '--button-login-disabled-text-color': '#FCDFB1', 
        'login-header-logo': 'assets/images/Visma-YouServe-White.svg',
        'logo': 'assets/images/Visma-YouServe-White.svg',
        'login-footer-logo': 'assets/images/poweredBy_Visma_logo.png'
    }
  };

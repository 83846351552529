<div #documentParent class="document-parent">
  <div class="document-loader-icon wait" [hidden]="!showBusy" ></div>
  <div class="max-width-page paperBackground" [hidden]="!showPages">
    <div #scrollRendererElement></div>
    <div #busyElement class="busy-message absolute-positioned">      
      <img class="busy-icon" src="assets\images\spinner_doughnut_grey_light.svg">      
    </div>
    <div #errorElement class="error-message" translate>error.fetching_documents</div>
    <!-- <div #footerElement class="footer-block" [hidden]="!showFooter">
      <div class="inner" fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
        <div>Door te tekenen gaat u akkoord met de inhoud van dit document</div>
        <button class="sign-button button-shadow" mat-raised-button (click)="buttonSign()">Ondertekenen</button>
    </div>
    </div> -->
  </div>
</div>

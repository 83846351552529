import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-inline-message',
  templateUrl: './inline-message.component.html',
  styleUrls: ['./inline-message.component.scss']
})
export class InlineMessageComponent {

  constructor() { }

  @Input()
  message: string;

  @Input()
  type: string;

  
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Language } from '../models/language.model';

@Injectable({
  providedIn: 'root'
})
export class CultureService {

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang(environment.defaultLanguage || 'en');
    this.languages = JSON.parse(JSON.stringify(environment.languages));
    this.currentLanguage = this.getLanguageWithCode(this.translate.currentLang, environment.defaultLanguage || 'en');
    this.currentStyle = environment.defaultStyle;    
  }

  currentLanguage: Language;
  languages: Language[];
  currentStyle: string;

  initializeLanguage() {
    const defaultLanguage = environment.defaultLanguage || 'en';
    this.translate.setDefaultLang(defaultLanguage);

    const fullLanguage = this.readFromStorage();
    let language = fullLanguage.language;
    const style = fullLanguage.style;

    if (!language) {
      language = this.translate.getBrowserCultureLang()?.substr(0, 2) || defaultLanguage;
    }

    return this.useLanguage(language, style);
  }

  setLanguage(language: string, style: string) {
    sessionStorage.setItem('language', language);
    sessionStorage.setItem('languageStyle', style);
  }

  useLanguage(requestedLanguageCode: string, requestedStyle: string = null): Observable<any> {
    let requestedLanguage = this.getLanguageWithCode(requestedLanguageCode, environment.defaultLanguage);
    
    // Set language to fall back to (style is not available)
    this.translate.setDefaultLang(requestedLanguage.code);

    let style = requestedStyle || this.currentStyle;

    if (style !== 'formal' && style !== 'informal') {
      style = environment.defaultStyle || 'formal';
    }

    const fullLanguageCode = requestedLanguage.code + '.' + style;

    this.currentLanguage = requestedLanguage;
    this.currentStyle = style;

    return this.translate.use(fullLanguageCode);
  }

  getAvailableLanguages(): Language[] {
    return this.languages;
  }

  private readFromStorage(): any {
    return {language: sessionStorage.getItem('language'), style: sessionStorage.getItem('languageStyle')};
  }

  private getLanguageWithCode(code: string, defaultCode: string = null): Language {
    if (!this.languages){return null;}

    // In the array of languages, find the one with the requested code
    // Do not use find() since it is not supported in all browsers
    var result = null;
    for (var i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code == code) {
        result = this.languages[i];
      }
    }

    if (!result && defaultCode) {
      for (var i = 0; i < this.languages.length; i++) {
        if (this.languages[i].code == defaultCode) {
          result = this.languages[i];
        }
      }      
    }

    if (!result) {
      result = (this.languages && this.languages.length > 0) ? this.languages[0] : null;
    }

    return result;
  }


}

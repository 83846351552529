import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpResponse, HttpEventType } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HttpAcceptLanguageInterceptorService {

  constructor(private injector: Injector,
              private http: HttpClient,
              private translate: TranslateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const language = this.translate.defaultLang || this.translate.getBrowserCultureLang();

    request = request.clone({
        headers: request.headers.set('Accept-Language', language)
      });

    return next.handle(request);
    }
}

export const HttpAcceptLanguageInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpAcceptLanguageInterceptorService,
  multi: true,
};

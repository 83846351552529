import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, APP_INITIALIZER, Injector } from '@angular/core';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthGuardService } from './core/services/auth-guard.service';
import { AuthService } from './core/services/auth.service';
import { HttpAuthorizationInterceptorProvider } from './core/services/httpAuthorizationInterceptor';
import { HttpAcceptLanguageInterceptorProvider } from './core/services/http-acceptLanguageInterceptor';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { visma } from './layout/themes/theme-visma';
import { vismaRaet } from './layout/themes/theme-visma-raet';
import { vismaYouServe } from './layout/themes/theme-visma-youserve';
import { defaultTheme } from './layout/themes/theme-default';
import { olympia } from './layout/themes/theme-olympia';
import { otto } from './layout/themes/theme-otto';
import { LoginInvitationComponent } from './pages/login/login-invitation/login-invitation.component';
import { SharedModule } from './shared/shared.module';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorHandlerService } from './core/services/error-handler.service';
import { HttpMockInterceptorProvider } from './core/services/http-mock-interceptor.service';
import { LoginDefaultComponent } from './pages/login/login-default/login-default.component';
import { LoginErrorComponent } from './pages/login/login-error/login-error.component';
import { LoginEndComponent } from './pages/login/login-end/login-end.component';
import { LoginCodeComponent } from './pages/login/login-code/login-code.component';
import {  HttpLoaderFactory } from './app.config';
import { VismaUnifiedDesignModule, VudDateAdapter, VudDateNativeUtcIsoAdapter } from '@vismaux/ngx-vud';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';
import { WootricDirective } from './core/services/wootric.directive';

registerLocaleData(localeNl);

@NgModule({
  declarations: [
    AppComponent,
    LoginDefaultComponent,
    LoginErrorComponent,
    LoginInvitationComponent,
    LoginEndComponent,
    LoginCodeComponent,
    WootricDirective
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    LayoutModule.forRoot({
      themes: [defaultTheme, visma, vismaRaet, vismaYouServe, olympia, otto],
      active: 'visma'
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    AppRoutingModule,
    FlexLayoutModule,
    SharedModule,
    MatToolbarModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    VismaUnifiedDesignModule 
  ],
  providers: [
    HttpAuthorizationInterceptorProvider,
    HttpAcceptLanguageInterceptorProvider,
    HttpMockInterceptorProvider,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    AuthGuardService,
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
